<template>
  <div class="content-body">
    <div class="row mg-0 no-print mb-3">
      <div class="col-md-12">
        <div class="content-header pd-l-0">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#">Role</a>
              </li>
              <li
                class="breadcrumb-item text-capitalize active"
                aria-current="page"
              >Assign Permission</li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="row col-md-12 role-section">
        <h4 class="content-title content-title-xs">New Role</h4>
        <div class="card" style="width: 100%">
          <div class="card-body">
            <div class="form-group">
              <label for="exampleInputEmail1">Name *</label>
              <input
                type="text"
                class="form-control"
                id="name"
                placeholder="Role Name"
                v-model="formData.name"
              />
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1">Description</label>
              <textarea
                name="description"
                id="description"
                rows="5"
                class="form-control"
                v-model="formData.description"
              ></textarea>
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1">Priority *</label>
             <input
                type="number"
                required
                class="form-control"
                id="name"
                placeholder="Priority"
                v-model="formData.priority"
              />
              <small class="tx-danger">**You are only able to change roles of priority above your priority (e.g. priority 1 can change of 2 and above)</small>
            </div>
            <div class="form-group">
              <label for="status">Status</label>
              <select name="status" id="status" class="form-control" v-model="formData.status">
                <option value="1">Active</option>
                <option value="0">Passive</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mg-0 no-print">
      <div class="col-md-6">
        <div class="content-header pd-l-0">
          <div>
            <h4 class="content-title content-title-xs">Assign Permission</h4>
          </div>
        </div>
      </div>
      <div class="col-md-6" v-if="!loading && permissions.length > 0">
        <div class="row">
          <div class="col-sm-6 custom-control custom-checkbox">
            <input
              type="checkbox"
              class="custom-control-input"
              id="select-all"
              v-model="selectAll"
              @change="selectAllPermissions"
            />

            <label class="custom-control-label" for="select-all">Select All</label>
          </div>
          <div class="col-md-6">
            <div style="text-align: end" v-if="selectAll">
              <button
                type="submit"
                class="btn btn-primary"
                @click="submitData()"
                :disabled="submitting"
              >
                <span v-if="submitting">Submitting...</span>
                <span v-else>Submit</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- row -->
    <div v-if="!loading && permissions.length > 0">
      <div class="mt-1" v-for="(parent, index) in permissions" :key="index">
        <div
          class="panel-group"
          :id="'accordion' + parent.slug"
          role="tablist"
          aria-multiselectable="true"
        >
          <div class="panel panel-default">
            <div class="panel-heading" role="tab" id="headingTwo">
              <div class="permission-header">
                <div class="custom-control custom-checkbox wd-80p">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    :id="'customCheck' + parent.slug"
                    v-model="parentSelected"
                    :value="parent.slug"
                    @change="parentChanged(parent,$event)"
                  />
                  <label
                    class="custom-control-label"
                    :for="'customCheck' + parent.slug"
                  >{{ parent.title }}</label>
                </div>
                <span
                  v-if="parent.child"
                  class="collapsed text-capitalize"
                  role="button"
                  data-toggle="collapse"
                  :data-parent="'#accordion' + parent.slug"
                  :href="'#collapse' + parent.slug"
                  aria-expanded="false"
                ></span>
              </div>
            </div>
            <!-- Child Loop -->
            <!-- ########################################### CHILD LOOOP ########################### -->
            <div
              :id="'collapse' + parent.slug"
              class="panel-collapse collapse"
              role="tabpanel"
              aria-labelledby="headingTwo"
            >
              <div class="panel-body ml-4" v-for="child in parent.child" :key="child.slug">
                <div class="row p-1">
                  <div class="col-sm-5 custom-control custom-checkbox p-">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      :id="'customCheck' + child.slug"
                      v-model="childSelected"
                      :value="child.slug"
                      @change="childChanged(child,parent, $event)"
                    />
                    <label
                      class="custom-control-label"
                      :for="'customCheck' + child.slug"
                    >{{ child.title }}</label>
                  </div>
                  <!-- permission -->
                  <!-- ######################################## PERMISSIONS LOOP ####################### -->
                  <div class="col-sm-7 d-flex">
                    <div
                      class="custom-control custom-switch mr-3"
                      v-for="permission in child.permissions"
                      :key="permission.slug"
                    >
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        :id="'createSwitch' + permission.slug"
                        v-model="permissionSelected"
                        :value="permission.slug"
                        @change="permissionChanged(child, parent,$event)"
                      />
                      <label
                        class="custom-control-label"
                        :for="'createSwitch' + permission.slug"
                      >{{ permission.title }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="text-align: end" class="mt-2">
        <button type="submit" class="btn btn-primary" @click="submitData()" :disabled="submitting">
          <span v-if="submitting">Submitting...</span>
          <span v-else>Submit</span>
        </button>
      </div>
    </div>
    <div v-else style="text-align: center">Loading...</div>
  </div>
</template>
<script>
import Services from "./Services/Service";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      permissions: [],
      parentSelected: [],
      childSelected: [],
      permissionSelected: [],
      loading: false,
      selectAll: false,
      submitting: false,
      errorLoading: false,
      formData: {
        name: "",
        description: "",
        status: 1,
        priority:"",
      },
    };
  },
  computed: {
    ...mapGetters(["eventMessage", "modalId"]),
  },
  beforeMount() {
    this.getPermissions();
    let id = this.$route.params.id;
    Services.getSelectedRoleData(id)
      .then((response) => {
        let value = response.data.data;
        this.formData.name = value.name;
        this.formData.priority = value.priority;
        this.formData.description = value.description;
        this.formData.status = value.status;
        this.permissionSelected = value.permissions;
        this.parentSelected = value.parent;
        this.childSelected = value.child;

        if (this.permissions.length == this.parentSelected.length) {
          this.selectAll = true;
        } else {
          this.selectAll = false;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    getPermissions() {
      this.loading = true;
      Services.getPermissions()
        .then((response) => {
          this.permissions = response.data.data;
        })
        .catch((error) => {
          if (
            !Object.prototype.hasOwnProperty.call(
              error,
              "response.data.error_message"
            )
          ) {
            if (error.response.data.error_message == "Access Denied.") {
              this.errorMessage = "Access Denied !!!";
              this.$router.push({ name: "access-denied" });
            } else {
              this.errorMessage = "Error fetching data from the server.";
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    submitData() {
      this.formData.permissions = this.permissionSelected;
      this.formData.parent = this.parentSelected;
      this.formData.child = this.childSelected;
      this.submitting = true;
      let id = this.$route.params.id;
      Services.updateRole(this.formData, id)
        .then((response) => {
          this.$router.push({ name: "list-roles" });
          setTimeout(() => {
            this.setNotification(response.data.success_message);
          }, 600);
        })
        .catch((error) => {
          console.log(error);
          this.errorLoading = true;
        })
        .finally(() => {
          this.submitting = false;
        });
    },

    selectAllPermissions(event) {
      console.log(event);
      if (event.target.checked) {
        this.selectParent(this.permissions);
      } else {
        this.parentSelected = [];
        this.childSelected = [];
        this.permissionSelected = [];
      }
    },

    parentChanged(parent, event) {
      console.log("asdas");
      if (event.target.checked) {
        parent.child.forEach((child) => {
          if (!this.childSelected.includes(child.slug)) {
            this.childSelected.push(child.slug);
          }
          this.childChanged(child, parent, event);
        });
      } else {
        parent.child.forEach((child) => {
          if (this.childSelected.includes(child.slug)) {
            let childIndex = this.childSelected.indexOf(child.slug);
            this.childSelected.splice(childIndex, 1);
          }
          this.childChanged(child, parent, event);
        });
      }
      this.allPermissionsSelected();
    },
    childChanged(child, parent, event) {
      // when child is clicked
      if (event.target.checked) {
        child.permissions.forEach((per) => {
          //if data doesnot exit already then only add else dont
          if (!this.permissionSelected.includes(per.slug)) {
            this.permissionSelected.push(per.slug);
          }
        });
      } else {
        child.permissions.forEach((per) => {
          if (this.permissionSelected.includes(per.slug)) {
            let perIndex = this.permissionSelected.indexOf(per.slug);
            this.permissionSelected.splice(perIndex, 1);
          }
        });
      }
      let childCount = parent.child.length;
      let parentCount = 0;
      parent.child.forEach((el) => {
        this.childSelected.forEach((cs) => {
          if (el.slug == cs) {
            parentCount++;
          }
        });
      });
      if (parentCount == childCount) {
        if (!this.parentSelected.includes(parent.slug)) {
          this.parentSelected.push(parent.slug);
        }
      } else {
        if (this.parentSelected.includes(parent.slug)) {
          let parentIndex = this.parentSelected.indexOf(parent.slug);
          this.parentSelected.splice(parentIndex, 1);
        }
      }
      this.allPermissionsSelected();
    },

    permissionChanged(child, parent, event) {
      let childPermissionCount = child.permissions.length;
      var selectedChildPermissionCount = 0;
      //when permission is checked
      if (event.target.checked) {
        this.permissionSelected.forEach((ps) => {
          child.permissions.forEach((cp) => {
            if (cp.slug == ps) {
              selectedChildPermissionCount++;
            }
          });
        });
        if (selectedChildPermissionCount == childPermissionCount) {
          this.childSelected.push(child.slug);
        }
        //when permission is deselected
      } else {
        if (this.childSelected.includes(child.slug)) {
          let childIndex = this.childSelected.indexOf(child.slug);
          this.childSelected.splice(childIndex, 1);
        }
      }
      //If all permissions of a child are selected then the child should also be selected
      var selectedChildCount = 0;
      parent.child.forEach((el) => {
        this.childSelected.forEach((cs) => {
          if (cs == el.slug) {
            selectedChildCount++;
          }
        });
      });
      if (selectedChildCount == parent.child.length) {
        this.parentSelected.push(parent.slug);
      } else {
        if (this.parentSelected.includes(parent.slug)) {
          let parentIndex = this.parentSelected.indexOf(parent.slug);
          this.parentSelected.splice(parentIndex, 1);
        }
      }
      this.allPermissionsSelected();
    },
    //to select the parent
    selectParent(permissions) {
      permissions.forEach((parent) => {
        if (!this.parentSelected.includes(parent.slug)) {
          this.parentSelected.push(parent.slug);
        }
        if (parent.child.length != 0) {
          this.selectChild(parent.child);
        }
      });
    },
    //to select the child
    selectChild(childs) {
      childs.forEach((child) => {
        if (!this.childSelected.includes(child.slug)) {
          this.childSelected.push(child.slug);
        }
        if (child.permissions.length != 0) {
          // permission loop
          this.selectPermission(child.permissions);
        }
      });
    },
    //to select the permission
    selectPermission(permissions) {
      permissions.forEach((permission) => {
        if (!this.permissionSelected.includes(permission.slug)) {
          this.permissionSelected.push(permission.slug);
        }
      });
    },
    //check whether all permissions/module are selected
    allPermissionsSelected() {
      if (this.permissions.length == this.parentSelected.length) {
        this.selectAll = true;
      } else {
        this.selectAll = false;
      }
    },
    setNotification(err) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", err);
    },
  },
};
</script>
<style>
.role-section {
  margin-left: 0px;
}
</style>